import React, { FC, useMemo } from 'react';

import { Navigate } from 'react-router-dom';
import routes from 'routers/routes';
import { useUserInfo } from 'utils/hooks/useUserInfo';

type withAuthenticationFn = (Component: FC, adminOnly?: boolean) => FC;

const withAuth: withAuthenticationFn = (Component, adminOnly = false) => {
  const Authenticated: FC = (props): JSX.Element | null => {
    const { loading, userInfo } = useUserInfo();
    const isAuthenticated = useMemo(() => !!userInfo, [userInfo]);
    const isAuthorized = useMemo(() => (adminOnly ? userInfo?.isAdmin || userInfo?.isSuperAdmin : true), [userInfo]);

    if (loading) {
      return null;
    }

    return isAuthenticated ? (
      isAuthorized ? (
        <Component {...props} />
      ) : (
        <Navigate to={routes.FORBIDDEN.path} />
      )
    ) : (
      <Navigate to={routes.LOGIN.path} />
    );
  };

  return Authenticated;
};

export default withAuth;
