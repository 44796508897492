import React, { useEffect, useMemo } from 'react';

import currentUserState from 'app-recoil/atoms/currentUser';
import FormTextField from 'components/FormTextField';
import LoadingButton from 'components/LoadingButton';
import { NAME_MAX_LENGTH } from 'constants/validation';
import PasswordForm from 'pages/users/components/PasswordForm';
import { useDeleteUser } from 'pages/users/hooks/useDeleteUser';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import routes from 'routers/routes';
import { User } from 'types/user';
import { useHasAuthorization } from 'utils/hooks/useHasAuthorization';

import { Box, Divider, Grid, InputAdornment, Typography } from '@mui/material';

import { IUserForm } from '../hooks/useUserForm';
import usePrompt from 'utils/hooks/usePrompt';

type UserFormProps = {
  loading: boolean;
  isNewUser?: boolean;
  data?: User;
  onSubmit: (formValue: IUserForm) => void;
};

const UserForm = ({ isNewUser = false, loading = false, data, onSubmit }: UserFormProps) => {
  const { t } = useTranslation('user-detail');
  const currentUser = useRecoilValue(currentUserState);
  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { isDirty },
  } = useFormContext<IUserForm>();

  const { handleDeleteUser, loading: isDeleting } = useDeleteUser(() => navigate(routes.USERS.path));

  useEffect(() => {
    if (data?.name) {
      reset({ name: data.name || '', email: data.email, isAdmin: !!data.isAdmin, password: '', confirmPassword: '' });
    }
  }, [data, reset]);

  const handleOnSubmit = (data: IUserForm) => {
    const { confirmPassword, ...rest } = data;
    onSubmit(rest);
  };

  const hasAuthorization = useHasAuthorization();
  const isEditable = useMemo(() => {
    return (data && hasAuthorization(data)) || isNewUser;
  }, [data, hasAuthorization]);

  usePrompt(isDirty);

  return (
    <Box component="form" noValidate autoComplete="off" onSubmit={handleSubmit(handleOnSubmit)} mt={2}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <FormTextField
            disabled={!isEditable}
            fullWidth
            name="name"
            control={control}
            label={t('fields.name')}
            autoFocus
            InputProps={{
              inputProps: {
                maxLength: NAME_MAX_LENGTH,
              },
              endAdornment: (
                <InputAdornment position="end">
                  <Typography>
                    {watch('name')?.length || 0} / {NAME_MAX_LENGTH}
                  </Typography>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <FormTextField disabled={!isEditable} fullWidth type="email" name="email" control={control} label={t('fields.email')} />
        </Grid>
        {isEditable && (
          <Grid item xs={12}>
            <PasswordForm isNewUser={isNewUser} isEditable={isEditable} />
          </Grid>
        )}
        {/*<Grid item xs={12}>*/}
        {/*  <FormCheckbox disabled={!isEditable} name="isAdmin" control={control} label={t('fields.isAdmin')} />*/}
        {/*</Grid>*/}
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="space-between">
            {isNewUser || !isEditable || currentUser?.id === data?.id ? null : (
              <LoadingButton
                loading={isDeleting}
                color="error"
                variant="contained"
                onClick={() => {
                  if (data) handleDeleteUser(data);
                }}
              >
                {t('button.remove')}
              </LoadingButton>
            )}
            <LoadingButton
              type="submit"
              color="primary"
              variant="contained"
              loading={loading}
              disabled={!isDirty || !isEditable}
              sx={{ ml: 'auto' }}
            >
              {t(`button.${isNewUser ? 'create' : 'save'}`)}
            </LoadingButton>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default UserForm;
