import React from 'react';

import bgLocale from 'date-fns/locale/vi';
import { Control, Controller, ControllerProps, FieldError, Path } from 'react-hook-form';
import { FieldValues } from 'react-hook-form/dist/types/fields';

import { TextField, TextFieldProps } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';

export declare type ParseableDate<TDate> = string | number | Date | null | undefined | TDate;

export type FormDatePickerProps<T extends FieldValues, TInputDate, TDate = TInputDate> = Omit<
  DatePickerProps<TInputDate, TDate>,
  'value' | 'onChange' | 'renderInput'
> & {
  name: Path<T>;
  required?: boolean;
  isDate?: boolean;
  parseError?: (error: FieldError) => string;
  onChange?: (value: TDate, keyboardInputValue?: string) => void;
  validation?: ControllerProps['rules'];
  parseDate?: (value: TDate, keyboardInputValue?: string) => TDate;
  control?: Control<T>;
  inputProps?: TextFieldProps;
  helperText?: TextFieldProps['helperText'];
};

export default function FormDatePicker<TFieldValues extends FieldValues>({
  isDate,
  parseError,
  name,
  required,
  parseDate,
  validation = {},
  inputProps,
  control,
  ...rest
}: FormDatePickerProps<TFieldValues, any, any>): JSX.Element {
  return (
    <Controller
      name={name}
      rules={validation}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error, invalid } }) => (
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={bgLocale} localeText={{}}>
          <DatePicker
            {...rest}
            value={value || ''}
            onChange={newValue => {
              onChange(newValue);
            }}
            aria-autocomplete="off"
            inputFormat="dd-MM-yyyy"
            dayOfWeekFormatter={(day: string) => day}
            renderInput={params => (
              <TextField
                {...params}
                name={name}
                error={invalid}
                fullWidth
                helperText={
                  error ? (typeof parseError === 'function' ? parseError(error) : error.message) : inputProps?.helperText || rest.helperText
                }
              />
            )}
          />
        </LocalizationProvider>
      )}
    />
  );
}
