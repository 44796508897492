import React from 'react';

import PageTitle from 'components/PageTitle';
import withAuth from 'HOCs/withAuth';
import UserTable from 'pages/users/components/UserTable';
import { useGetUserList } from 'pages/users/hooks/useGetUserList';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import routes from 'routers/routes';
import { User } from 'types/user';

import { Box, Button } from '@mui/material';

const UsersPage = () => {
  const { t } = useTranslation('user-list');
  const { userList } = useGetUserList();

  return (
    <>
      <Box sx={{ display: { xs: 'block', sm: 'flex' } }} justifyContent="space-between" alignItems="center">
        <PageTitle title={t('title')} />
        <Button
          variant="contained"
          component={Link}
          to={routes.USERS.path + '/create'}
          sx={{ mb: 3, float: { xs: 'right', sm: 'none' } }}
          size="large"
        >
          {t('button.create')}
        </Button>
      </Box>
      <UserTable
        userList={userList
          ?.filter((user: User) => !user.isSuperAdmin && !user.isAdmin)
          ?.sort((user: User, targetUser: User) => {
            return Number(targetUser?.isAdmin) - Number(user?.isAdmin) || (user?.name || '').localeCompare(targetUser?.name || '');
          })}
      />
    </>
  );
};

export default withAuth(UsersPage, true);
