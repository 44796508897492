import { atom, selector } from 'recoil';
import { User } from 'types/user';

export const currentUserStateKey = 'currentUserStateKey';
const currentUserState = atom<User | null>({
  key: currentUserStateKey,
  default: null,
});

export const isAdminState = selector({
  key: 'isAdmin',
  get: ({ get }) => {
    const currentUser = get(currentUserState);
    return currentUser?.isAdmin;
  },
});
export default currentUserState;
