import React from 'react';

import { Link } from 'react-router-dom';

import { Box, Button, Typography } from '@mui/material';

type EmptyComponentProps = {
  title: string;
  content: string;
  buttonLabel: string;
  returnUrl: string;
};

const EmptyComponent = ({ title, content, buttonLabel, returnUrl }: EmptyComponentProps) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', mt: 5 }}>
      <Typography component="h1" sx={{ mt: 5, fontSize: '2.5rem', textTransform: 'capitalize' }} variant="h3" gutterBottom>
        {title}
      </Typography>
      <Typography paragraph className="lead" variant="h5" textAlign="center" dangerouslySetInnerHTML={{ __html: content }} />
      <Button variant="contained" color="primary" component={Link} to={returnUrl} size="large">
        {buttonLabel}
      </Button>
    </Box>
  );
};

export default EmptyComponent;
