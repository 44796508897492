import { History, Transition } from 'history';
import { useCallback, useContext, useEffect } from 'react';
import { Navigator } from 'react-router';
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';
import useConfirm from 'utils/hooks/useConfirm';
import { useTranslation } from 'react-i18next';

type ExtendNavigator = Navigator & Pick<History, 'block'>;

export function useBlocker(blocker: (tx: Transition) => void, when = true) {
  const { navigator } = useContext(NavigationContext);

  useEffect(() => {
    if (!when) return;

    const unblock = (navigator as ExtendNavigator).block((tx: Transition) => {
      const autoUnblockingTx = {
        ...tx,
        retry() {
          unblock();
          tx.retry();
        },
      };

      blocker(autoUnblockingTx);
    });

    return unblock;
  }, [navigator, blocker, when]);
}

export default function usePrompt(when = true) {
  const { t } = useTranslation();
  const { confirm } = useConfirm();
  const blocker = useCallback(async (tx: Transition) => {
    const isConfirmed = await confirm({
      title: t('common:confirm_leave_modal.title'),
      message: t('common:confirm_leave_modal.message'),
      confirmButtonLabel: t('common:buttons.ok'),
      confirmButtonVariant: 'primary',
    });

    if (isConfirmed) {
      tx.retry();
    }
  }, []);

  useBlocker(blocker, when);
}
