import { useMemo } from 'react';

import currentUserState from 'app-recoil/atoms/currentUser';
import { NAME_MAX_LENGTH } from 'constants/validation';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import * as Yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';

export interface IMyAccountForm {
  name: string;
  password: string;
  confirmPassword?: string;
}

export const useMyAccountForm = (isNewUser = false) => {
  const { t } = useTranslation('my-account');
  const currentUser = useRecoilValue(currentUserState);

  const validationSchema = Yup.object().shape(
    {
      name: Yup.string()
        .trim()
        .required(t('common:errors.required'))
        .max(NAME_MAX_LENGTH, t('errors.name_max_length', { length: NAME_MAX_LENGTH })),
      password: Yup.string().when('password', val => {
        if (val?.length > 0) {
          return Yup.string()
            .min(8, t('errors.password_min_length'))
            .max(16, t('errors.password_max_length'))
            .matches(/^(?:(?!["';<=>\\])[\x20-\x7E])+$/, t('common:errors.password_has_invalid_characters'));
        } else if (isNewUser) {
          return Yup.string().required(t('common:errors.required'));
        } else {
          return Yup.string().notRequired();
        }
      }),
      confirmPassword: Yup.string().oneOf([Yup.ref('password')], t('errors.not_match_password')),
    },
    [['password', 'password']]
  );

  const defaultValues = useMemo(() => {
    return {
      name: currentUser?.name || '',
      password: '',
      confirmPassword: '',
    };
  }, [currentUser]);

  return useForm<IMyAccountForm>({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });
};
