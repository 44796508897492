import { useCallback, useState } from 'react';

export const usePasswordType = () => {
  const [passwordType, setPasswordType] = useState('password');

  const togglePasswordType = useCallback(() => {
    setPasswordType(passwordType === 'password' ? 'text' : 'password');
  }, [passwordType]);

  return { passwordType, togglePasswordType };
};
