import { useCallback } from 'react';

import currentUserState from 'app-recoil/atoms/currentUser';
import { useLocation } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { GET_CURRENT_USER_INFO } from 'services/api/auth';
import cookieSrv from 'services/cookie';

import { DocumentNode, useQuery } from '@apollo/client';

export const useUserInfo = () => {
  const [currentUser, setCurrentUser] = useRecoilState(currentUserState);
  const location = useLocation();

  const savePastUrl = useCallback(() => {
    cookieSrv.pastUrl = location.pathname;
  }, []);

  const { loading, data: userInfo } = useQuery(GET_CURRENT_USER_INFO as unknown as DocumentNode, {
    skip: !!currentUser?.name,
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      if (data?.authenticatedItem) {
        setCurrentUser(data.authenticatedItem);
      } else {
        savePastUrl();
      }
    },
    onError: () => savePastUrl(),
  });

  return {
    loading,
    userInfo: userInfo?.authenticatedItem || currentUser,
  };
};
