import React from 'react';

import { useTranslation } from 'react-i18next';
import useConfirm from 'utils/hooks/useConfirm';

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

const ConfirmBox = () => {
  const { t } = useTranslation();
  const { confirmBox, onConfirm, onCancel } = useConfirm();
  const { title, message, confirmButtonLabel, confirmButtonVariant } = confirmBox;

  return (
    <Dialog open={confirmBox.show} onClose={onCancel} maxWidth="sm">
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText dangerouslySetInnerHTML={{ __html: message }} />
      </DialogContent>

      <DialogActions>
        <Button type="button" variant="outlined" color="secondary" onClick={onCancel} size="large">
          {t('common:buttons.cancel')}
        </Button>
        <Button type="button" color={confirmButtonVariant} variant="contained" onClick={onConfirm} size="large">
          {confirmButtonLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmBox;
