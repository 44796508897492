import { useState } from 'react';

import { GET_USER_DETAIL } from 'services/api/user';
import { User } from 'types/user';

import { DocumentNode, useQuery } from '@apollo/client';

export const useGetUserDetail = (id: string) => {
  const [userDetail, setUserDetail] = useState<User>();
  const { loading } = useQuery(GET_USER_DETAIL as unknown as DocumentNode, {
    variables: { where: { id } },
    skip: !id || id === 'create',
    onCompleted: data => setUserDetail(data?.user),
  });

  return {
    loading,
    setUserDetail,
    userDetail,
  };
};
