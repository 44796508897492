import { atom } from 'recoil';

import { ButtonPropsColorOverrides } from '@mui/material/Button/Button';
import { OverridableStringUnion } from '@mui/types';

export const defaultConfirmBox: ConfirmBoxStateProps = {
  show: false,
  title: '',
  message: '',
  confirmButtonLabel: '',
  confirmButtonVariant: 'primary',
};

export type ConfirmBoxStateProps = {
  show: boolean;
  title: string;
  message: string;
  confirmButtonLabel: string;
  confirmButtonVariant: OverridableStringUnion<
    'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning',
    ButtonPropsColorOverrides
  >;
};

const confirmBoxState = atom({
  key: 'confirmBox',
  default: defaultConfirmBox as ConfirmBoxStateProps,
});

export default confirmBoxState;
