import cookie from 'js-cookie';
import { UI_CONST } from 'constants/url';

const cookieSrv = {
  get xsrfToken() {
    return cookie.get('XSRF-TOKEN') || '';
  },

  set xsrfToken(xsrfToken: string) {
    cookie.set('XSRF-TOKEN', xsrfToken);
  },

  get userToken() {
    return cookie.get(UI_CONST.TOKEN_LOGGED_IN) || '';
  },

  set userToken(token: string) {
    cookie.set(UI_CONST.TOKEN_LOGGED_IN, token, { expires: 30 });
  },

  clearUserToken: () => {
    cookie.remove(UI_CONST.TOKEN_LOGGED_IN);
  },

  get pastUrl() {
    return cookie.get(UI_CONST.PAST_URL) || '';
  },

  set pastUrl(url: string) {
    cookie.set(UI_CONST.PAST_URL, url, { expires: 30 });
  },

  clearPastUrl: () => {
    cookie.remove(UI_CONST.PAST_URL);
  },
};

export default cookieSrv;
