import React from 'react';

import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import routes from 'routers/routes';

import { Link } from '@mui/material';

const BackToUserListButton = () => {
  const { t } = useTranslation('user-detail');

  return (
    <Link underline="hover" component={RouterLink} to={routes.USERS.path} sx={{ mb: 3, textAlign: { xs: 'right' }, display: 'block' }}>
      {t('button.back')}
    </Link>
  );
};

export default BackToUserListButton;
