import './style.scss';
import 'react-datepicker/dist/react-datepicker.css';

import React, { useMemo, useState } from 'react';

import FormDatePicker from 'components/FormDatePicker';
import FormRadioButton from 'components/FormRadioButton';
import FormTextField from 'components/FormTextField';
import LoadingButton from 'components/LoadingButton';
import PageTitle from 'components/PageTitle';
import { Sex } from 'constants/sex';
import { UI_URL } from 'constants/url';
import { NAME_MAX_LENGTH } from 'constants/validation';
import { format } from 'date-fns';
import FileSaver from 'file-saver';
import withAuth from 'HOCs/withAuth';
import { useCreatePdf } from 'pages/create-pdf/hooks/useCreatePdf';
import { useAuthen } from 'pages/login/hooks/useAuthen';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { CreatePdfFormValues } from 'types/create-pdf-form';
import { isDevMode, isProdMode } from 'utils/helper';
import * as Yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Card, CardContent, Divider, Grid, InputAdornment, Typography } from '@mui/material';

const random = (length = 8) => {
  // Declare all characters
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';

  // Pick characers randomly
  let str = '';
  for (let i = 0; i < length; i++) {
    str += chars.charAt(Math.floor(Math.random() * chars.length));
  }

  return str;
};

const CreatePdfPage = () => {
  const { t } = useTranslation('create-pdf');
  const { addClient } = useCreatePdf();
  const [loading, setLoading] = useState(false);
  const { handleLogout } = useAuthen();

  const validationSchema = Yup.object({
    name: Yup.string()
      .trim()
      .required(t('common:errors.required'))
      .matches(/^[a-zA-Z\s]+$/g, t('errors.wrong_name_format'))
      .max(NAME_MAX_LENGTH, t('errors.name_max_length', { length: NAME_MAX_LENGTH })),
    iVowelsOfY: Yup.number()
      .typeError(t('errors.int_iVowels'))
      .nullable()
      .required(t('common:errors.required'))
      .integer(t('errors.int_iVowels'))
      .when('test', (name: string, schema) => {
        return schema
          .test({
            test: (iVowelsOfY: number) => {
              if (!iVowelsOfY) return true;
              return iVowelsOfY <= amountOfY;
            },
            message: t('errors.iVowelsOfY'),
          })
          .min(0, t('errors.min_iVowels'));
      }),
    phone: Yup.string()
      .trim()
      .required(t('common:errors.required'))
      .matches(/^\+?\d{2}[- ]?\d{3}[- ]?\d{5}$/, t('errors.phone_format')),
    email: Yup.string().trim().email(t('common:errors.wrong_email_format')).required(t('common:errors.required')),
    dob: Yup.date()
      .typeError(t('common:errors.invalid_date')) // ex: Expected a value of type date but got: Invalid Date
      .nullable()
      .max(new Date(), t('errors.max_dob'))
      .required(t('common:errors.required')),
    sex: Yup.string().required(t('common:errors.required')),
  });

  const { control, handleSubmit, watch } = useForm<CreatePdfFormValues>({
    defaultValues: isDevMode()
      ? {
          name: random(6).concat(' ').concat(random(6)),
          iVowelsOfY: null,
          phone: '0909990999',
          email: 'client01@mail.com',
          dob: undefined,
          sex: Sex.Male,
        }
      : {
          name: '',
          iVowelsOfY: null,
          phone: '',
          email: '',
          dob: undefined,
          sex: undefined,
        },
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (data: CreatePdfFormValues) => {
    setLoading(true);
    addClient({
      variables: {
        ...data,
        dob: format(data.dob || 0, 'dd-MM-yyyy'),
      },
    }).then(
      result => {
        !isProdMode() && console.log(result);
        const { errors } = result;
        if (errors?.[0].extensions?.code === 'KS_ACCESS_DENIED') {
          handleLogout();
          return toast.error(t('common:errors.access_denied'), { toastId: 'ERROR_PDF_CREATING' });
        }
        const pdfInfo = result.data?.createNumerologyClient;
        if (pdfInfo?.pdfLink === 'ERROR_PDF_CREATING') {
          return toast.error(t('errors.create_pdf'), { toastId: 'ERROR_PDF_CREATING' });
        }
        if (pdfInfo) {
          //pdfInfo?.pdfLink kind of `files/test.25325cahx.pdf`
          let url = pdfInfo?.pdfLink;
          const iFileNameLoc = (url?.lastIndexOf('/') ? url?.lastIndexOf('/') : 0) + 1;
          const fileName = url?.slice(iFileNameLoc, url?.indexOf('.', iFileNameLoc)).concat('.pdf');

          // if not in dev mode, we need to specify the server location
          url = isDevMode() ? url : `${UI_URL.SERVER_LOCATION}/${url}`;
          !isProdMode() && console.log(`PDF download link is: ${url}`);

          if (/webOS|iPhone|iPad|iPod|Opera Mini/i.test(navigator.userAgent)) {
            window.open(url);
            !isProdMode() && console.log(`ios`);
          } else {
            !isProdMode() && console.log(`desktop`);
            // run your code here
            FileSaver.saveAs(url as string, fileName);
          }
          toast.success(t('message.create_pdf_success'), { toastId: 'PDF_CREATED' });
          setTimeout(() => setLoading(false), 2000);
        }
      },
      err => {
        console.error(err);
        setLoading(false);
      }
    );
  };

  const amountOfY = useMemo(() => (watch('name')?.match(/y/gi) || []).length, [watch('name')]);
  const sexOptions = [
    { label: t('male'), id: Sex.Male },
    { label: t('female'), id: Sex.Female },
  ];

  return (
    <Box>
      <PageTitle title={t('title')} />
      <Card>
        <CardContent>
          <Box component="form" noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)} mt={2}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <FormTextField
                  fullWidth
                  name="name"
                  control={control}
                  label={t('name')}
                  autoFocus
                  InputProps={{
                    inputProps: {
                      maxLength: NAME_MAX_LENGTH,
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <Typography>
                          {watch('name')?.length || 0} / {NAME_MAX_LENGTH}
                        </Typography>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormTextField
                  fullWidth
                  type="number"
                  InputProps={{ inputProps: { min: 0 } }}
                  name="iVowelsOfY"
                  control={control}
                  label={t('iVowelsOfY')}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormDatePicker label={t('dob')} control={control} name="dob" maxDate={new Date()} />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormRadioButton row options={sexOptions} label={t('sex')} control={control} name="sex" />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormTextField fullWidth type="text" name="phone" control={control} label={t('phone')} />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormTextField fullWidth type="email" name="email" control={control} label={t('email')} />
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12} justifyContent="flex-end" display="flex">
                <LoadingButton type="submit" loading={loading} className="submit-button" size="large">
                  {t('buttons.submit')}
                </LoadingButton>
              </Grid>
            </Grid>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};
export default withAuth(CreatePdfPage);
