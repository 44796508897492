import './style.scss';

import React, { useState } from 'react';

import currentUserState from 'app-recoil/atoms/currentUser';
import FormTextField from 'components/FormTextField';
import LoadingButton from 'components/LoadingButton';
import { useAuthen } from 'pages/login/hooks/useAuthen';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import routes from 'routers/routes';
import cookieSrv from 'services/cookie';
import { isDevMode } from 'utils/helper';
import { usePasswordType } from 'utils/hooks/usePasswordType';
import * as Yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Alert, Avatar, Box, Card, CardContent, Divider, IconButton, InputAdornment, Tooltip, Typography } from '@mui/material';

interface ILoginForm {
  email: string;
  password: string;
}

const LoginPage = () => {
  const { t } = useTranslation('login');
  const navigate = useNavigate();
  const { isLoading, loginWithEmailMutation } = useAuthen();
  const [isShowAlert, setIsShowAlert] = useState(false);
  const setCurrentUser = useSetRecoilState(currentUserState);

  const validationSchema = Yup.object({
    email: Yup.string().trim().email(t('common:errors.wrong_email_format')).required(t('common:errors.required')),
    password: Yup.string().required(t('common:errors.required')),
  });

  const { control, handleSubmit } = useForm<ILoginForm>({
    defaultValues: isDevMode()
      ? {
          email: 'user1@email.com',
          password: '12345678X',
        }
      : {
          email: '',
          password: '',
        },
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = ({ email, password }: ILoginForm) => {
    loginWithEmailMutation({ variables: { email, password } }).then(
      result => {
        const { data } = result;
        const authenResult = data?.authenticateUserWithPassword;
        if (authenResult?.__typename === 'UserAuthenticationWithPasswordSuccess') {
          const { id, name, isAdmin, isSuperAdmin, email } = authenResult.item;
          setCurrentUser({ id, name, isAdmin, isSuperAdmin, email: email || '' });
          navigate(cookieSrv.pastUrl || routes.HOME.path, { replace: true });
          cookieSrv.clearPastUrl();
        } else if (authenResult?.__typename === 'UserAuthenticationWithPasswordFailure') {
          console.error(authenResult?.message);
        } else {
          console.error('An unknown error occurred');
        }
        setIsShowAlert(true);
      },
      error => {
        console.error(error.toString());
        setIsShowAlert(true);
      }
    );
  };

  const { passwordType, togglePasswordType } = usePasswordType();

  return (
    <div className="login">
      <Card>
        <CardContent>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5" sx={{ mb: 3 }}>
              {t('buttons.submit')}
            </Typography>

            {isShowAlert && (
              <Box sx={{ mb: 3, width: '100%' }}>
                <Alert severity="error" onClose={() => setIsShowAlert(false)}>
                  {t('errors.credential')}
                </Alert>
              </Box>
            )}

            <Box component="form" noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
              <FormTextField margin="normal" fullWidth name="email" control={control} label={t('email')} autoFocus />
              <FormTextField
                margin="normal"
                fullWidth
                name="password"
                type={passwordType}
                control={control}
                label={t('password')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={togglePasswordType}
                        edge="end"
                        sx={{ width: '56px', height: '56px' }}
                      >
                        <Tooltip title={t('tooltip.password')}>{passwordType === 'password' ? <VisibilityOff /> : <Visibility />}</Tooltip>
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Divider sx={{ mt: 3 }} />
              <LoadingButton type="submit" loading={isLoading} sx={{ mt: 3, mb: 2, width: '100%' }}>
                {t('buttons.submit')}
              </LoadingButton>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </div>
  );
};
export default LoginPage;
