import * as React from 'react';

import { useDeleteUser } from 'pages/users/hooks/useDeleteUser';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import routes from 'routers/routes';
import { User } from 'types/user';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { IconButton, Tooltip, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

interface Column {
  id: keyof User | 'index';
  label: string;
  minWidth?: number;
  align?: 'right' | 'left' | 'center';
  renderCell?: (value: boolean | string | number | null | undefined) => JSX.Element | null;
}

type UserTableProps = {
  userList: User[];
};

const UserTable = ({ userList }: UserTableProps) => {
  const { t } = useTranslation('user-list');

  const navigate = useNavigate();

  const { handleDeleteUser } = useDeleteUser();

  const columns: readonly Column[] = [
    { id: 'index', label: t('table.columns.index'), minWidth: 40, renderCell: value => <Typography>{value}</Typography>, align: 'center' },
    { id: 'name', label: t('table.columns.name'), minWidth: 200 },
    { id: 'email', label: t('table.columns.email'), minWidth: 120 },
  ];

  const goToUserDetail = (id: string) => navigate(`${routes.USERS.path}/${id}`);

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: 'calc(100vh - 134px - 68px - 24px - 45px)' }}>
        <Table stickyHeader aria-label="User table" size="small">
          <TableHead>
            <TableRow>
              <>
                {columns.map(column => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth, fontStyle: 'italic', fontWeight: 'bold' }}
                  >
                    {column.label}
                  </TableCell>
                ))}
                <TableCell />
              </>
            </TableRow>
          </TableHead>
          <TableBody>
            {userList?.map((user, index) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={user.email}>
                  <>
                    {columns.map(column => {
                      const value = column.id === 'index' ? index + 1 : user[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.renderCell ? column.renderCell(value) : value}
                        </TableCell>
                      );
                    })}
                    <TableCell style={{ minWidth: 100 }}>
                      <Tooltip title={t('tooltip.edit')}>
                        <IconButton
                          sx={{ mr: '20px' }}
                          aria-label="edit"
                          color="primary"
                          onClick={event => {
                            event.stopPropagation();
                            goToUserDetail(user.id);
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                      {!user.isAdmin && (
                        <Tooltip title={t('tooltip.delete')}>
                          <IconButton
                            aria-label="delete"
                            color="error"
                            onClick={event => {
                              event.stopPropagation();
                              handleDeleteUser(user);
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                    </TableCell>
                  </>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default UserTable;
