import './style.scss';

import React from 'react';

import { useUserInfo } from 'utils/hooks/useUserInfo';
import { Box, Typography } from '@mui/material';

const HomePage = () => {
  const { loading, userInfo } = useUserInfo();

  if (loading) {
    return null;
  }

  return (
    <Box component="main" role="main" textAlign="center">
      {userInfo ? (
        <>
          <Typography component="h1" sx={{ mt: 5, fontSize: '2.5rem', textTransform: 'capitalize' }} variant="h3" gutterBottom>
            Hệ thống tạo PDF nhân số học
          </Typography>
          <Typography paragraph className="lead" variant="h5">
            Một sản phẩm của{' '}
            <Typography component="code" sx={{ color: '#d63384', fontSize: 24 }}>
              Captain Gen
            </Typography>
          </Typography>
        </>
      ) : (
        <Typography paragraph className="lead text-info" variant="h5">
          Vui lòng đăng nhập để sử dụng trang web.
        </Typography>
      )}
    </Box>
  );
};

export default HomePage;
