import * as React from 'react';
import { useCallback, useMemo } from 'react';

import currentUserState, { isAdminState } from 'app-recoil/atoms/currentUser';
import { useAuthen } from 'pages/login/hooks/useAuthen';
import { Trans, useTranslation } from 'react-i18next';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import routes from 'routers/routes';

import MenuIcon from '@mui/icons-material/Menu';
import { ClickAwayListener, Grow, Link, MenuList, Paper, Popper } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import Logo from '../../logo.svg';

const navItems = [routes.CREATE_PDF, routes.USERS];

const NavBar = () => {
  const { t } = useTranslation();
  const isAdmin = useRecoilValue(isAdminState);
  const { pathname } = useLocation();
  const { handleLogout } = useAuthen();

  const currentUser = useRecoilValue(currentUserState);

  const isHideLoginBtn = useMemo(
    () => pathname !== routes.LOGIN.path && pathname !== routes.NOT_FOUND.path,
    [pathname, routes.LOGIN.path, routes.NOT_FOUND.path]
  );

  const filteredNavItems = useMemo(() => navItems.filter(item => isAdmin === item.isAdmin || !item.isAdmin), [navItems, isAdmin]);

  const navButtons = useMemo(
    () =>
      currentUser &&
      filteredNavItems.map(item => (
        <Link
          underline="hover"
          key={item.path}
          component={RouterLink}
          to={item.path}
          sx={{ mr: 2, display: 'block', textTransform: 'none' }}
        >
          {t(item.message)}
        </Link>
      )),
    [currentUser]
  );

  const renderLogo = useCallback(
    () => (
      <Box
        component="img"
        sx={{
          maxWidth: 60,
        }}
        src={Logo}
        alt="Captain Gen's logo"
      />
    ),
    []
  );

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <AppBar position="static" sx={{ py: 1, background: 'white', boxShadow: 'none' }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Link
            variant="h6"
            noWrap
            component={RouterLink}
            to={routes.HOME.path}
            sx={{
              mr: 3,
              maxWidth: 60,
              display: 'flex',
            }}
          >
            {renderLogo()}
          </Link>

          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>{navButtons}</Box>

          <Box sx={{ flexGrow: 0, display: 'flex', alignItems: 'center' }}>
            <Box sx={{ display: currentUser ? 'flex' : 'none', alignItems: 'center' }}>
              <Typography sx={{ mr: 2, flexShrink: 0, color: 'black', display: 'flex', whiteSpace: 'pre-wrap' }}>
                <Trans
                  i18nKey="navbar.greeting" // optional -> fallbacks to defaults if not provided
                  defaults="Xin chào, <routerlink>{{name}}</routerlink>" // optional defaultValue
                  values={{ name: currentUser?.name }}
                  components={{ routerlink: <Link component={RouterLink} to={routes.MY_ACCOUNT.path} className="username" /> }}
                />
              </Typography>
              <Button variant="outlined" color="secondary" onClick={handleLogout} size="large" sx={{ display: { xs: 'none', md: 'flex' } }}>
                {t(routes.LOGOUT.message)}
              </Button>
              <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                <IconButton
                  size="large"
                  color="primary"
                  ref={anchorRef}
                  id="composition-button"
                  aria-controls={open ? 'composition-menu' : undefined}
                  aria-expanded={open ? 'true' : undefined}
                  aria-haspopup="true"
                  onClick={handleToggle}
                >
                  <MenuIcon />
                </IconButton>
                <Popper
                  open={open}
                  sx={{ zIndex: 9 }}
                  anchorEl={anchorRef.current}
                  role={undefined}
                  placement="bottom-start"
                  transition
                  disablePortal
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom',
                      }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={handleClose}>
                          <MenuList
                            autoFocusItem={open}
                            id="composition-menu"
                            aria-labelledby="composition-button"
                            onKeyDown={handleListKeyDown}
                          >
                            <>
                              {filteredNavItems.map(item => (
                                <MenuItem key={item.path} component={RouterLink} to={item.path} onClick={handleClose}>
                                  <Typography textAlign="center">{item.message}</Typography>
                                </MenuItem>
                              ))}
                              <MenuItem onClick={handleLogout}>{t(routes.LOGOUT.message)}</MenuItem>
                            </>
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </Box>
            </Box>
            <Box sx={{ display: !currentUser ? 'flex' : 'none' }}>
              {isHideLoginBtn && (
                <Button variant="contained" color="primary" component={RouterLink} to={routes.LOGIN.path} size="large">
                  {t(routes.LOGIN.message)}
                </Button>
              )}
            </Box>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default NavBar;
