import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { DELETE_USER, GET_USER_LIST } from 'services/api/user';
import { User } from 'types/user';
import useConfirm from 'utils/hooks/useConfirm';

import { DocumentNode, useMutation } from '@apollo/client';

export const useDeleteUser = (onSuccess?: () => void) => {
  const { t } = useTranslation('user-list');

  const [deleteUser, { loading }] = useMutation(DELETE_USER as unknown as DocumentNode, {
    refetchQueries: [{ query: GET_USER_LIST as unknown as DocumentNode, variables: { where: {}, take: 50, skip: 0 } }],
  });
  const { confirm } = useConfirm();

  const handleDeleteUser = async (user: User) => {
    const isConfirmed = await confirm({
      title: t('delete_user_confirm_modal.title'),
      message: t('delete_user_confirm_modal.message', {
        name: user.name,
      }),
      confirmButtonLabel: t('common:buttons.delete'),
      confirmButtonVariant: 'error',
    });

    if (isConfirmed) {
      deleteUser({ variables: { where: { id: user.id } } }).then(() => {
        toast.success(t('delete_success', { name: user.name }));
        onSuccess?.();
      });
    }
  };

  return {
    loading,
    handleDeleteUser,
  };
};
