import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';

i18n
  .use(Backend)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    lng: 'vn', // if you're using a language detector, do not define the lng option
    fallbackLng: 'vn',
    ns: ['login', 'create-pdf', 'forbidden', 'user-list', 'user-detail', 'not-found', 'my-account'],
    defaultNS: ['common'],
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });
