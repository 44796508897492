import React, { useCallback, useState } from 'react';

import FormTextField from 'components/FormTextField';
import { IUserForm } from 'pages/users/hooks/useUserForm';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { usePasswordType } from 'utils/hooks/usePasswordType';

import { Visibility, VisibilityOff } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Tooltip } from '@mui/material';

type PasswordFormProps = {
  isEditable: boolean;
  isNewUser: boolean;
};

const PasswordForm = ({ isEditable, isNewUser }: PasswordFormProps) => {
  const { t } = useTranslation('user-detail');
  const [changePassword, setChangePassword] = useState(!isNewUser);

  const { passwordType, togglePasswordType } = usePasswordType();

  const { control, resetField } = useFormContext<IUserForm>();

  const toggleChangePassword = useCallback(() => setChangePassword(!changePassword), [changePassword, setChangePassword]);

  const handleResetPassword = () => {
    toggleChangePassword();
    resetField('password');
    resetField('confirmPassword');
  };

  const renderPasswordForm = () => (
    <Box display="flex" columnGap={2} alignItems="flex-start" sx={{ rowGap: { xs: 4 }, flexWrap: { xs: 'wrap', md: 'nowrap' } }}>
      <FormTextField
        disabled={!isEditable}
        fullWidth
        name="password"
        type={passwordType}
        control={control}
        label={t(`fields.${isNewUser ? 'password' : 'new-password'}`)}
      />
      <FormTextField
        disabled={!isEditable}
        fullWidth
        name="confirmPassword"
        type={passwordType}
        control={control}
        label={t('fields.confirm_password')}
      />
      <Tooltip title={t('tooltip.password')}>
        <Button variant="outlined" color="secondary" onClick={togglePasswordType} size="small" sx={{ width: '55px', height: '55px' }}>
          {passwordType === 'password' ? <VisibilityOff /> : <Visibility />}
        </Button>
      </Tooltip>
      {!isNewUser ? (
        <Tooltip title={t('tooltip.reset_password')}>
          <Button variant="outlined" color="secondary" onClick={handleResetPassword} size="small" sx={{ width: '55px', height: '55px' }}>
            <CloseIcon />
          </Button>
        </Tooltip>
      ) : null}
    </Box>
  );

  return !changePassword || isNewUser ? (
    renderPasswordForm()
  ) : (
    <Button size="large" variant="text" onClick={toggleChangePassword} sx={{ width: 160 }}>
      {t('button.change_password')}
    </Button>
  );
};

export default PasswordForm;
