import React from 'react';

import EmptyComponent from 'components/EmptyComponent';
import withAuth from 'HOCs/withAuth';
import { useTranslation } from 'react-i18next';

const ForbiddenPage = () => {
  const { t } = useTranslation('forbidden');

  return <EmptyComponent title="403!" content={t('subtitle')} buttonLabel={t('button')} returnUrl="/" />;
};

export default withAuth(ForbiddenPage);
