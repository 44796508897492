import { useRecoilState } from 'recoil';
import confirmBoxState, { ConfirmBoxStateProps, defaultConfirmBox } from 'app-recoil/atoms/confirm-box';

let resolve: (value: unknown) => void;
const useConfirm = () => {
  const [confirmBox, setConfirmBox] = useRecoilState(confirmBoxState);

  const confirm = (args: Omit<ConfirmBoxStateProps, 'show'>) => {
    const { title, message, confirmButtonLabel, confirmButtonVariant = 'primary' } = args || {};
    return new Promise(res => {
      setConfirmBox({
        ...defaultConfirmBox,
        show: true,
        title,
        message,
        confirmButtonLabel,
        confirmButtonVariant,
      });
      resolve = res;
    });
  };

  const onConfirm = () => {
    setConfirmBox({ ...confirmBox, show: false });
    resolve?.(true);
  };

  const onCancel = () => {
    setConfirmBox({ ...confirmBox, show: false });
    resolve?.(false);
  };

  return { confirm, confirmBox, onCancel, onConfirm };
};

export default useConfirm;
