import './App.scss';
import 'react-toastify/dist/ReactToastify.css';

import React, { useCallback, useMemo } from 'react';

import apiLoadingState from 'app-recoil/atoms/api-loading';
import ConfirmBox from 'components/ConfirmBox';
import Navbar from 'components/NavBar';
import Loading from 'components/SpinnerLoading';
import CreatePdfPage from 'pages/create-pdf';
import ForbiddenPage from 'pages/forbiden';
import HomePage from 'pages/home';
import LoginPage from 'pages/login';
import MyAccountPage from 'pages/my-account';
import NotFoundPage from 'pages/NotFound';
import UsersPage from 'pages/users';
import CreateUserPage from 'pages/users/CreateUser';
import UserDetailPage from 'pages/users/UserDetail';
import { useTranslation } from 'react-i18next';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { useSetRecoilState } from 'recoil';
import routes from 'routers/routes';
import cookieSrv from 'services/cookie';
import { isDevMode, isProdMode, isTestMode } from 'utils/helper';

import { ApolloClient, ApolloLink, ApolloProvider, createHttpLink, from, InMemoryCache, ServerError } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { Box, Container, Typography } from '@mui/material';
import { OperationDefinitionNode } from 'graphql';

function App() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const doLogout = useCallback(() => {
    cookieSrv.clearUserToken();
    cookieSrv.pastUrl = window.location.href;
    navigate(routes.LOGIN.path);
  }, []);

  const errorHandler = onError(({ networkError }) => {
    if (networkError && (networkError as ServerError).statusCode === 401) {
      doLogout();
    } else if (networkError) {
      toast.error(t('errors.general'));
    }
  });

  // default we're in development environment, we use proxy, so no host url here
  let hostURL;

  // if docker test environment
  if (isTestMode()) {
    hostURL = process.env.REACT_APP_BE_URL_DOCKER_TEST;
  }

  // if docker production environment
  if (isProdMode()) {
    hostURL = process.env.REACT_APP_BE_URL_DOCKER_PROD;
  }

  // if hostURL is not set, it should be empty string
  hostURL = hostURL ? hostURL : '';

  if (!isProdMode()) {
    console.log(`isTestMode is ${isTestMode()}`);
    console.log(`isProdMode is ${isProdMode()}`);
    console.log(`REACT_APP_DEPLOY_ENV is ${process.env.REACT_APP_DEPLOY_ENV}`);
    console.log(`NODE_ENV is ${process.env.NODE_ENV}`);
    console.log(`Current hostURL is ${hostURL}`);
  }

  const uri = `${hostURL}/api/graphql`;

  // https://www.apollographql.com/docs/react/networking/authentication/
  const link = createHttpLink({
    uri: uri,
    credentials: 'include',
  });

  const setApiLoading = useSetRecoilState(apiLoadingState);

  const loadingMiddleware = new ApolloLink((operation, forward) => {
    const operationType = (operation.query.definitions[0] as OperationDefinitionNode).operation;
    if (operationType === 'query') {
      setApiLoading(true);
    }

    return forward(operation).map(response => {
      setTimeout(() => setApiLoading(false), 1000);
      return response;
    });
  });

  // Create a client
  const client = useMemo(
    () =>
      new ApolloClient({
        cache: new InMemoryCache(),
        link: errorHandler.concat(from([loadingMiddleware, link])),
      }),
    []
  );

  return (
    <ApolloProvider client={client}>
      <ToastContainer />
      <ConfirmBox />
      <Box sx={{ minHeight: '100vh' }} className="App">
        <Loading />
        <Navbar />
        <Container className="container" sx={{ py: 4 }} maxWidth="md">
          <Routes>
            <Route path={routes.LOGIN.path} element={<LoginPage />} />
            <Route path={routes.CREATE_PDF.path} element={<CreatePdfPage />} />
            <Route path={routes.CREATE_USER.path} element={<CreateUserPage />} />
            <Route path={routes.USER_DETAIL.path} element={<UserDetailPage />} />
            <Route path={routes.USERS.path} element={<UsersPage />} />
            <Route path={routes.FORBIDDEN.path} element={<ForbiddenPage />} />
            <Route path={routes.HOME.path} element={<HomePage />} />
            <Route path={routes.NOT_FOUND.path} element={<NotFoundPage />} />
            <Route path={routes.MY_ACCOUNT.path} element={<MyAccountPage />} />
            <Route path="*" element={<Navigate to={routes.NOT_FOUND.path} />} />
          </Routes>
        </Container>
        <Box
          component="footer"
          sx={{
            py: 2,
            px: 2,
            mt: 'auto',
            color: theme => theme.palette.secondary.main,
            backgroundColor: 'white',
          }}
        >
          <Container sx={{ textAlign: 'center' }}>
            <Typography variant="body1" sx={{ fontStyle: 'italic', fontSize: 14 }}>
              © 2022, {process.env.REACT_APP_SYSTEM_NAME_FULL}. Phiên bản {isDevMode() && 'DEV-'}v{process.env.REACT_APP_VERSION}-
              {process.env.REACT_APP_GIT_SHA}
            </Typography>
          </Container>
        </Box>
      </Box>
    </ApolloProvider>
  );
}

export default App;
