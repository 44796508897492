import { isDevMode, isProdMode, isTestMode } from 'utils/helper';

const createPdfPath = '/createPdf';

// it will be kind of `http://dev-be:2901`
const serverLocation = (() => {
  // default we're in development environment
  let hostURL = process.env.REACT_APP_BE_URL_LOCAL;

  // if docker test environment
  if (isTestMode()) {
    hostURL = process.env.REACT_APP_BE_URL_DOCKER_TEST;
  }

  // if docker production environment
  if (isProdMode()) {
    hostURL = process.env.REACT_APP_BE_URL_DOCKER_PROD;
  }

  return hostURL;
})();

const clientLocation = 'http://localhost:3000';
const adminPanelUrl = serverLocation;

let clientUrl = '/';
if (isDevMode()) clientUrl = clientLocation;
// console.log("is dev mode: %j", isDevMode());

export const UI_URL = {
  CLIENT_LOCATION: clientUrl,
  HOME: '/',
  CREATE_PDF: createPdfPath,
  ABOUT: '/about',
  SIGN_IN: '/login',
  SIGN_OUT: '/logout',
  USERS: '/users',
  CREATE_USER: '/users/create',
  USER_DETAIL: '/users/:id',
  FORBIDDEN: '/forbidden',
  NOT_FOUND: '/not-found',
  MY_ACCOUNT: '/my-account',
  ADMIN_PANEL_URL: adminPanelUrl,
  SERVER_LOCATION: serverLocation,
};
export const UI_CONST = {
  USER: {
    ROLE: {
      ADMIN: 'admin',
      STAFF: 'staff',
    },
  },
  SNACKBAR: {
    TYPE: {
      SUCCESS: 'success',
      ERROR: 'error',
    },
  },
  LOCAL_STORAGE_USER: 'userInfo',
  TOKEN_LOGGED_IN: 'logged_in',
  EVENT_LOGGED_OUT: 'logged_out',
  NOTIFY_DEFAULT_TIMEOUT: 6000, //	ms
  PAST_URL: 'pastUrl',
} as const;
