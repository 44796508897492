import currentUserState from 'app-recoil/atoms/currentUser';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import routes from 'routers/routes';
import { AUTHENTICATE_USER_WITH_PASSWORD, LOG_OUT } from 'services/api/auth';

import { useMutation } from '@ts-gql/apollo';

export const useAuthen = () => {
  const setCurrentUser = useSetRecoilState(currentUserState);
  const navigate = useNavigate();

  const [loginWithEmailMutation, { loading }] = useMutation(AUTHENTICATE_USER_WITH_PASSWORD, {
    refetchQueries: ['AuthenticatedItem'],
  });

  const [logout] = useMutation(LOG_OUT);

  const handleLogout = () => {
    setCurrentUser(null);
    navigate(routes.LOGIN.path);
    logout();
  };

  return {
    isLoading: loading,
    loginWithEmailMutation,
    logout,
    handleLogout,
  };
};
