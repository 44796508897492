import React from 'react';

import currentUserState from 'app-recoil/atoms/currentUser';
import EmptyComponent from 'components/EmptyComponent';
import PageTitle from 'components/PageTitle';
import withAuth from 'HOCs/withAuth';
import BackToUserListButton from 'pages/users/components/BackToUserListButton';
import { useGetUserDetail } from 'pages/users/hooks/useGetUserDetail';
import { useUpdateUser } from 'pages/users/hooks/useUpdateUser';
import { IUserForm, useUserForm } from 'pages/users/hooks/useUserForm';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useRecoilState } from 'recoil';
import routes from 'routers/routes';
import { User } from 'types/user';

import { Box, Card, CardContent } from '@mui/material';

import UserForm from './components/UserForm';

export type UserFormProps = {
  name: string;
  email: string;
  isAdmin: boolean;
  password?: string;
  confirmPassword?: string;
};

const UserDetailPage = () => {
  const { t } = useTranslation('user-detail');
  const { id = '' } = useParams();
  const [currentUser, setCurrentUser] = useRecoilState(currentUserState);

  const { userDetail, setUserDetail, loading: isGettingUserDetail } = useGetUserDetail(id);
  const { loading, updateUser } = useUpdateUser();
  const methods = useUserForm();
  const { setError } = methods;

  const handleOnSubmit = (data: Omit<IUserForm, 'confirmPassword'>) => {
    updateUser({
      variables: {
        data: {
          ...data,
          password: data.password || undefined,
        },
        where: { id },
      },
    })
      .then(result => {
        const { data, errors } = result;

        if (errors) {
          toast.error(t('message.update_fail'));
          if (errors[0].extensions?.prisma?.code === 'P2002') {
            setError('email', { type: 'custom', message: t('errors.prisma.email_duplicate') });
          }
          if (errors[0]?.message.includes('- User.password: Password is too common and is not allowed')) {
            setError('password', { type: 'custom', message: t('errors.password_too_common') });
          }
        } else {
          setUserDetail(data?.updateUser as unknown as User);
          if (currentUser?.id === data?.updateUser?.id) {
            setCurrentUser({ ...currentUser, ...(data?.updateUser as unknown as User) });
          }
          toast.success(t('message.update_success'));
        }
      })
      .catch(error => {
        console.error(error);
      });
  };

  if (isGettingUserDetail) {
    return null;
  }

  if (!userDetail) {
    return (
      <EmptyComponent
        title={t('errors.user_not_found.title')}
        content={t('errors.user_not_found.content')}
        buttonLabel={t('button.back')}
        returnUrl={routes.USERS.path}
      />
    );
  }

  return (
    <Box>
      <Box sx={{ display: { xs: 'block', sm: 'flex' } }} justifyContent="space-between" alignItems="center">
        <PageTitle title={t('title.edit')} />
        <BackToUserListButton />
      </Box>
      <Card>
        <CardContent>
          <FormProvider {...methods}>
            <UserForm loading={loading} onSubmit={handleOnSubmit} data={userDetail} />
          </FormProvider>
        </CardContent>
      </Card>
    </Box>
  );
};

export default withAuth(UserDetailPage, true);
