import * as Yup from 'yup';
import { NAME_MAX_LENGTH } from 'constants/validation';
import { useMemo } from 'react';
import { isDevMode } from 'utils/helper';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';

const random = (length = 8) => {
  // Declare all characters
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';

  // Pick characers randomly
  let str = '';
  for (let i = 0; i < length; i++) {
    str += chars.charAt(Math.floor(Math.random() * chars.length));
  }

  return str;
};

export interface IUserForm {
  name: string;
  email: string;
  isAdmin: boolean;
  password: string;
  confirmPassword?: string;
}

export const useUserForm = (isNewUser = false) => {
  const { t } = useTranslation('user-detail');

  const validationSchema = Yup.object().shape(
    {
      name: Yup.string()
        .trim()
        .required(t('common:errors.required'))
        .max(NAME_MAX_LENGTH, t('errors.name_max_length', { length: NAME_MAX_LENGTH })),
      email: Yup.string().trim().email(t('common:errors.wrong_email_format')).required(t('common:errors.required')),
      password: Yup.string().when('password', val => {
        if (val?.length > 0) {
          return Yup.string()
            .min(8, t('errors.password_min_length'))
            .max(16, t('errors.password_max_length'))
            .matches(/^(?:(?!["';<=>\\])[\x20-\x7E])+$/, t('common:errors.password_has_invalid_characters'));
        } else if (isNewUser) {
          return Yup.string().required(t('common:errors.required'));
        } else {
          return Yup.string().notRequired();
        }
      }),
      confirmPassword: Yup.string().oneOf([Yup.ref('password')], t('errors.not_match_password')),
      isAdmin: Yup.boolean(),
    },
    [['password', 'password']]
  );

  const defaultValues = useMemo(() => {
    return {
      name: '',
      email: '',
      password: '',
      confirmPassword: '',
      isAdmin: false,
    };
  }, []);

  return useForm<IUserForm>({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });
};
