import React from 'react';

import { TextField, TextFieldProps } from '@mui/material';
import { Control, Controller, ControllerProps, FieldError, Path } from 'react-hook-form';
import { FieldValues } from 'react-hook-form/dist/types/fields';

type FormTextFieldProps<T extends FieldValues = FieldValues> = Omit<TextFieldProps, 'name'> & {
  validation?: ControllerProps['rules'];
  name: Path<T>;
  parseError?: (error: FieldError) => string;
  control?: Control<T>;
};

function FormTextField<TFieldValues extends FieldValues = FieldValues>({
  validation = {},
  parseError,
  type,
  required,
  name,
  control,
  ...rest
}: FormTextFieldProps<TFieldValues>): JSX.Element {
  return (
    <Controller
      name={name}
      control={control}
      rules={validation}
      render={({ field: { value, onChange, onBlur }, fieldState: { invalid, error } }) => (
        <TextField
          {...rest}
          name={name}
          value={value ?? ''}
          onChange={ev => {
            onChange(ev);
            if (typeof rest.onChange === 'function') {
              rest.onChange(ev);
            }
          }}
          onKeyDown={event => {
            if (type === 'number') {
              /**
               * Allowing: Integers | Backspace | Tab | Delete | Left & Right arrow keys
               **/

              const regex = new RegExp(/(^\d*$)|(Backspace|Tab|Delete|ArrowLeft|ArrowRight)/);

              return !event.key.match(regex) && event.preventDefault();
            }
          }}
          onBlur={onBlur}
          required={required}
          type={type}
          error={invalid}
          helperText={error ? (typeof parseError === 'function' ? parseError(error) : error.message) : rest.helperText}
        />
      )}
    />
  );
}
export default FormTextField;
