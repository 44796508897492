import React from 'react';

import currentUserState from 'app-recoil/atoms/currentUser';
import PageTitle from 'components/PageTitle';
import withAuth from 'HOCs/withAuth';
import { useAuthen } from 'pages/login/hooks/useAuthen';
import MyAccountForm from 'pages/my-account/components/MyAccountForm';
import { IMyAccountForm, useMyAccountForm } from 'pages/my-account/hooks/useMyAccountForm';
import { useUpdateMyAccount } from 'pages/my-account/hooks/useUpdateMyAccount';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useRecoilState } from 'recoil';
import { User } from 'types/user';

import { Box, Card, CardContent } from '@mui/material';
import usePrompt from 'utils/hooks/usePrompt';

const MyAccountPage = () => {
  const { t } = useTranslation('my-account');
  const methods = useMyAccountForm();
  const { setError } = methods;
  const { loading, updateMyAccount } = useUpdateMyAccount();
  const [currentUser, setCurrentUser] = useRecoilState(currentUserState);
  const { handleLogout } = useAuthen();

  usePrompt(methods.formState.isDirty);

  const handleOnSubmit = (data: Omit<IMyAccountForm, 'confirmPassword'>) => {
    updateMyAccount({
      variables: {
        data: {
          ...data,
          password: data.password || undefined,
        },
        where: { id: currentUser?.id },
      },
    })
      .then(result => {
        const { data, errors } = result;

        if (errors) {
          if (errors?.[0].extensions?.code === 'KS_ACCESS_DENIED') {
            handleLogout();
            return toast.error(t('common:errors.access_denied'), { toastId: 'ERROR_PDF_CREATING' });
          }
          toast.error(t('message.update_fail'));
          if (errors[0]?.message.includes('- User.password: Password is too common and is not allowed')) {
            setError('password', { type: 'custom', message: t('errors.password_too_common') });
          }
        } else {
          setCurrentUser({ ...currentUser, ...(data?.updateUser as unknown as User) });

          toast.success(t('message.update_success'));
        }
      })
      .catch(error => {
        console.error(error);
      });
  };

  return (
    <Box>
      <PageTitle title={t('title')} />
      <Card>
        <CardContent>
          <FormProvider {...methods}>
            <MyAccountForm loading={loading} onSubmit={handleOnSubmit} />
          </FormProvider>
        </CardContent>
      </Card>
    </Box>
  );
};

export default withAuth(MyAccountPage);
