import React, { useEffect, useState } from 'react';

import PageTitle from 'components/PageTitle';
import withAuth from 'HOCs/withAuth';
import BackToUserListButton from 'pages/users/components/BackToUserListButton';
import { useCreateUser } from 'pages/users/hooks/useCreateUser';
import { useUserForm } from 'pages/users/hooks/useUserForm';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import routes from 'routers/routes';

import { Box, Card, CardContent } from '@mui/material';

import UserForm from './components/UserForm';
import { GraphQLError } from 'graphql/error/GraphQLError';

export type UserFormProps = {
  name: string;
  email: string;
  isAdmin: boolean;
  password?: string;
  confirmPassword?: string;
};

// Error Code for DB: https://www.prisma.io/docs/reference/api-reference/error-reference#prisma-client-query-engine
// Error from KS: https://keystonejs.com/docs/graphql/overview#errors
// Custom errors from BE should have the forms like ERR_EMAIL_IS_EMPTY, ERR_NAME_IS_EMPTY
function parseCustomeErrs(errs: readonly GraphQLError[]) {
  let errCode: string[] = [];

  errs.forEach((err: GraphQLError) => {
    const errs = err.message?.match(/([ERR])\w+/g);
    if (errs) errCode = errCode.concat(errs);
  });

  return errCode;
}

const CreateUserPage = () => {
  const { t } = useTranslation('user-detail');
  const navigate = useNavigate();
  const { loading, createUser } = useCreateUser();
  const [isNavigating, setIsNavigating] = useState(false);

  const methods = useUserForm(true);
  const { setError, reset } = methods;

  const handleOnSubmit = (data: UserFormProps) => {
    createUser({ variables: { data } })
      .then(result => {
        const { errors } = result;

        if (errors) {
          toast.error(t('message.create_fail'));
          console.log(parseCustomeErrs(errors));
          if (errors[0].extensions?.prisma?.code === 'P2002') {
            setError('email', { type: 'custom', message: t('errors.prisma.email_duplicate') });
          }
          if (errors[0]?.message.includes('- User.password: Password is too common and is not allowed')) {
            setError('password', { type: 'custom', message: t('errors.password_too_common') });
          }
        } else {
          toast.success(t('message.create_success'));
          reset(undefined, { keepDirty: false });
          setIsNavigating(true);
        }
      })
      .catch(error => console.error(error));
  };

  useEffect(() => {
    if (isNavigating) {
      navigate(routes.USERS.path);
    }
  }, [isNavigating]);

  return (
    <Box>
      <Box sx={{ display: { xs: 'block', sm: 'flex' } }} justifyContent="space-between" alignItems="center">
        <PageTitle title={t('title.create')} />
        <BackToUserListButton />
      </Box>
      <Card>
        <CardContent>
          <FormProvider {...methods}>
            <UserForm isNewUser loading={loading} onSubmit={handleOnSubmit} />
          </FormProvider>
        </CardContent>
      </Card>
    </Box>
  );
};

export default withAuth(CreateUserPage, true);
