import { UPDATE_USER_DETAIL } from 'services/api/user';

import { DocumentNode, useMutation } from '@apollo/client';

export const useUpdateUser = () => {
  const [updateUser, { loading }] = useMutation(UPDATE_USER_DETAIL as unknown as DocumentNode, { errorPolicy: 'all' });

  return {
    loading,
    updateUser,
  };
};
