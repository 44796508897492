import { CREATE_USER } from 'services/api/user';

import { DocumentNode, useMutation } from '@apollo/client';

export const useCreateUser = () => {
  const [createUser, { loading }] = useMutation(CREATE_USER as unknown as DocumentNode, { errorPolicy: 'all' });

  return {
    loading,
    createUser,
  };
};
