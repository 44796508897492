import React, { useEffect, useMemo } from 'react';

import currentUserState from 'app-recoil/atoms/currentUser';
import FormTextField from 'components/FormTextField';
import LoadingButton from 'components/LoadingButton';
import { NAME_MAX_LENGTH } from 'constants/validation';
import { IMyAccountForm } from 'pages/my-account/hooks/useMyAccountForm';
import PasswordForm from 'pages/users/components/PasswordForm';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import { Box, Divider, Grid, InputAdornment, TextField, Typography } from '@mui/material';

type UserFormProps = {
  loading: boolean;
  onSubmit: (formValue: IMyAccountForm) => void;
};

const MyAccountForm = ({ loading = false, onSubmit }: UserFormProps) => {
  const { t } = useTranslation('my-account');
  const currentUser = useRecoilValue(currentUserState);

  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { isDirty },
  } = useFormContext<IMyAccountForm>();

  useEffect(() => {
    if (currentUser?.name) {
      reset({ name: currentUser.name || '', password: '', confirmPassword: '' });
    }
  }, [currentUser, reset]);

  const email = useMemo(() => currentUser?.email, [currentUser]);

  const handleOnSubmit = (data: IMyAccountForm) => {
    const { confirmPassword, ...rest } = data;
    onSubmit(rest);
  };

  return (
    <Box component="form" noValidate autoComplete="off" onSubmit={handleSubmit(handleOnSubmit)} mt={2}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <FormTextField
            fullWidth
            name="name"
            control={control}
            label={t('fields.name')}
            autoFocus
            InputProps={{
              inputProps: {
                maxLength: NAME_MAX_LENGTH,
              },
              endAdornment: (
                <InputAdornment position="end">
                  <Typography>
                    {watch('name')?.length || 0} / {NAME_MAX_LENGTH}
                  </Typography>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField disabled fullWidth label={t('fields.email')} value={email} />
        </Grid>
        <Grid item xs={12}>
          <PasswordForm isNewUser={false} isEditable />
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="flex-end">
            <LoadingButton type="submit" color="primary" variant="contained" loading={loading} disabled={!isDirty} sx={{ ml: 'auto' }}>
              {t(`button.save`)}
            </LoadingButton>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default MyAccountForm;
