import { gql } from '@ts-gql/tag/no-transform';

export const GET_USER_LIST = gql`
  query GetUserList($where: UserWhereInput!, $take: Int, $skip: Int!) {
    users(where: $where, take: $take, skip: $skip) {
      id
      ... on User {
        id
        name
        email
        isAdmin
        isSuperAdmin
      }
    }
  }
` as import('../../../__generated__/ts-gql/GetUserList').type;

export const GET_USER_DETAIL = gql`
  query GetUserDetail($where: UserWhereUniqueInput!) {
    user(where: $where) {
      id
      ... on User {
        id
        name
        email
        isAdmin
        isSuperAdmin
      }
    }
  }
` as import('../../../__generated__/ts-gql/GetUserDetail').type;

export const CREATE_USER = gql`
  mutation CreateUser($data: UserCreateInput!) {
    createUser(data: $data) {
      id
      ... on User {
        id
        name
        email
        isAdmin
        isSuperAdmin
      }
    }
  }
` as import('../../../__generated__/ts-gql/CreateUser').type;

export const UPDATE_USER_DETAIL = gql`
  mutation UpdateUser($where: UserWhereUniqueInput!, $data: UserUpdateInput!) {
    updateUser(where: $where, data: $data) {
      id
      ... on User {
        id
        name
        email
        isAdmin
        isSuperAdmin
      }
    }
  }
` as import('../../../__generated__/ts-gql/UpdateUser').type;

export const DELETE_USER = gql`
  mutation DeleteUser($where: UserWhereUniqueInput!) {
    deleteUser(where: $where) {
      id
    }
  }
` as import('../../../__generated__/ts-gql/DeleteUser').type;
