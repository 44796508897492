import { useCallback } from 'react';

import currentUserState from 'app-recoil/atoms/currentUser';
import { useRecoilValue } from 'recoil';
import { User } from 'types/user';

export const useHasAuthorization = () => {
  const currentUser = useRecoilValue(currentUserState);

  return useCallback(
    (targetedUser: User) => (<boolean>currentUser?.isAdmin && <boolean>!targetedUser.isAdmin) || currentUser?.id === targetedUser.id,
    [currentUser]
  );
};
