import { UI_URL } from 'constants/url';

import messages from './messages';

type AppRouteProps = {
  name: string;
  path: string;
  icon: string;
  message: string;
  isAdmin?: boolean;
};
export default {
  HOME: {
    name: 'home',
    path: UI_URL.HOME,
    icon: 'home',
    message: messages.home.defaultMessage,
  },
  CREATE_PDF: {
    name: 'createPdf',
    path: UI_URL.CREATE_PDF,
    icon: 'export',
    message: messages.createPdf.defaultMessage,
  },

  LOGIN: {
    name: 'login',
    path: UI_URL.SIGN_IN,
    icon: '',
    message: messages.login.defaultMessage,
  },
  LOGOUT: {
    name: 'logout',
    path: UI_URL.SIGN_OUT,
    icon: '',
    message: messages.logout.defaultMessage,
  },
  FORBIDDEN: {
    name: 'forbidden',
    path: UI_URL.FORBIDDEN,
    icon: '',
    message: messages.forbidden.defaultMessage,
  },
  USERS: {
    name: 'users',
    path: UI_URL.USERS,
    icon: '',
    message: messages.users.defaultMessage,
    isAdmin: true,
  },
  USER_DETAIL: {
    name: 'userDetail',
    path: UI_URL.USER_DETAIL,
    icon: '',
    message: messages.userDetail.defaultMessage,
    isAdmin: true,
  },
  CREATE_USER: {
    name: 'createUser',
    path: UI_URL.CREATE_USER,
    icon: '',
    message: messages.createUser.defaultMessage,
    isAdmin: true,
  },
  MY_ACCOUNT: {
    name: 'myAccount',
    path: UI_URL.MY_ACCOUNT,
    icon: '',
    message: messages.myAccount.defaultMessage,
    isAdmin: true,
  },
  ADMIN_PANEL: {
    name: 'adminPanel',
    path: UI_URL.ADMIN_PANEL_URL,
    icon: '',
    message: messages.adminPanel.defaultMessage,
    isAdmin: true,
  },
  NOT_FOUND: {
    name: 'notFound',
    path: UI_URL.NOT_FOUND,
    icon: '',
    message: messages.notFound.defaultMessage,
  },
} as { [key: string]: AppRouteProps };
