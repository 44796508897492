import { DocumentNode, useMutation } from '@apollo/client';
import { gql } from '@ts-gql/tag/no-transform';

const NEW_CLIENT = gql`
  mutation NewClient($name: String!, $iVowelsOfY: Int!, $email: String!, $dob: String!, $sex: NumerologyClientSexType!, $phone: String!) {
    createNumerologyClient(data: { name: $name, iVowelsOfY: $iVowelsOfY, email: $email, dob: $dob, sex: $sex, phone: $phone }) {
      __typename
      id
      label: name
      pdfLink
    }
  }
` as import('../../../../__generated__/ts-gql/NewClient').type;

export const useCreatePdf = () => {
  const [addClient, { loading }] = useMutation(NEW_CLIENT as unknown as DocumentNode, { errorPolicy: 'all' });

  return {
    isLoading: loading,
    addClient,
  };
};
