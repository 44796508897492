import { gql } from '@ts-gql/tag/no-transform';

// export type LoginArgs = { email: string; password: string };
// export type LoginResult = { success: true; userInfo: UserInfo } | { success: false; userInfo: null; message: string };
export type UserInfo = { id: string; name: string | null } | null;

// The UserInfo response is just a small subset of actual data
// Use `getCurrentUserInfo` to retrieve the full data. (You should also update the GraphQL)
export const AUTHENTICATE_USER_WITH_PASSWORD = gql`
  mutation AuthenticateUser($email: String!, $password: String!) {
    authenticateUserWithPassword(email: $email, password: $password) {
      __typename
      ... on UserAuthenticationWithPasswordSuccess {
        item {
          id
          name
          isAdmin
          isSuperAdmin
          email
        }
      }
      ... on UserAuthenticationWithPasswordFailure {
        message
      }
    }
  }
` as import('../../../__generated__/ts-gql/AuthenticateUser').type;

export const LOG_OUT = gql`
  mutation EndSession {
    endSession
  }
` as import('../../../__generated__/ts-gql/EndSession').type;

// export const login = async ({ email, password }: LoginArgs): Promise<LoginResult> => {
//   try {
//     const result = await authenticate({
//       variables: { email, password },
//       refetchQueries: ['AuthenticatedItem'],
//     });
//     const authenResult = result.data?.authenticateUserWithPassword;
//     if (authenResult?.__typename === 'UserAuthenticationWithPasswordSuccess') {
//       return { success: true, userInfo: authenResult.item };
//     } else if (authenResult?.__typename === 'UserAuthenticationWithPasswordFailure') {
//       throw authenResult?.message;
//     }
//     throw 'An unknown error occurred';
//   } catch (err: any) {
//     throw { success: false, userInfo: null, message: err.toString() };
//   }
// };

export const GET_CURRENT_USER_INFO = gql`
  query AuthenticatedItem {
    authenticatedItem {
      ... on User {
        id
        name
        isAdmin
        isSuperAdmin
        email
      }
    }
  }
` as import('../../../__generated__/ts-gql/AuthenticatedItem').type;

// export const logout = () => {
//   const [logoutMutation] = useMutation(
//     gql`
//       mutation EndSession {
//         endSession
//       }
//     ` as import('../../../__generated__/ts-gql/EndSession').type
//   );

//   logoutMutation({ refetchQueries: ['AuthenticatedItem'] });
// };
