const scope = 'app.routes';
const messages = {
  home: {
    id: `${scope}.home`,
    defaultMessage: 'Trang Chủ',
  },
  createPdf: {
    id: `${scope}.createPdf`,
    defaultMessage: 'Tạo PDF',
  },
  // search: {
  //   id: `${scope}.search`,
  //   defaultMessage: 'Tìm Kiếm',
  // },
  // others: {
  //   id: `${scope}.others`,
  //   defaultMessage: 'Khác',
  // },
  login: {
    id: `${scope}.login`,
    defaultMessage: 'Đăng Nhập',
  },
  logout: {
    id: `${scope}.logout`,
    defaultMessage: 'Đăng Xuất',
  },
  forbidden: {
    id: `${scope}.forbidden`,
    defaultMessage: 'Không có quyền truy cập',
  },
  users: {
    id: `${scope}.users`,
    defaultMessage: 'Bảng Điều Khiển Admin',
  },
  createUser: {
    id: `${scope}.createUser`,
    defaultMessage: 'Tạo tài khoản',
  },
  myAccount: {
    id: `${scope}.myAccount`,
    defaultMessage: 'Tài khoản',
  },
  userDetail: {
    id: `${scope}.userDetail`,
    defaultMessage: 'Thông tin tài khoản',
  },
  notFound: {
    id: `${scope}.notFound`,
    defaultMessage: 'Trang 404',
  },
  adminPanel: {
    id: `${scope}.adminPanel`,
    defaultMessage: 'Bảng Điều Khiển Admin',
  },
  about: {
    id: `${scope}.about`,
    defaultMessage: 'Thông Tin',
  },
};

export default messages;
