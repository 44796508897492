import React from 'react';

import { Typography } from '@mui/material';

type PageTitleProps = {
  title: string;
};

const PageTitle = ({ title }: PageTitleProps) => {
  return (
    <Typography component="h1" variant="h4" mb={3}>
      {title}
    </Typography>
  );
};

export default PageTitle;
