import './style.scss';

import React, { useEffect, useState } from 'react';

import apiLoadingState from 'app-recoil/atoms/api-loading';
import { useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { Box, CircularProgress } from '@mui/material';

const Loading = () => {
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const apiLoading = useRecoilValue(apiLoadingState);

  useEffect(() => {
    setIsLoading(true);

    setTimeout(() => setIsLoading(false), 500);
  }, [location]);

  useEffect(() => {
    setIsLoading(apiLoading);
  }, [apiLoading]);

  return isLoading ? (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      className="overlay"
      sx={{ bgcolor: theme => theme.palette.secondary.light }}
    >
      <CircularProgress sx={{ color: theme => theme.palette.primary.dark }} size={80} thickness={4} />
    </Box>
  ) : null;
};

export default Loading;
