import { GET_USER_LIST } from 'services/api/user';

import { DocumentNode, useQuery } from '@apollo/client';

export const useGetUserList = () => {
  const { data, loading } = useQuery(GET_USER_LIST as unknown as DocumentNode, {
    variables: { where: {}, take: 50, skip: 0 },
    fetchPolicy: 'network-only',
  });

  return {
    isLoading: loading,
    userList: data?.users,
  };
};
