import React from 'react';

import EmptyComponent from 'components/EmptyComponent';
import { useTranslation } from 'react-i18next';
import routes from 'routers/routes';

const NotFoundPage = () => {
  const { t } = useTranslation('not-found');

  return <EmptyComponent title={t('title')} content={t('message')} buttonLabel={t('button.home')} returnUrl={routes.HOME.path} />;
};

export default NotFoundPage;
