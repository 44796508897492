import React, { ReactElement } from 'react';

import { Button, ButtonProps, CircularProgress } from '@mui/material';

interface LoadingButtonProps extends ButtonProps {
  loading: boolean;
  children: ReactElement;
  color?: 'primary' | 'secondary' | 'error' | 'warning' | 'info' | 'success';
}

const LoadingButton = (props: LoadingButtonProps) => {
  const { loading, children, disabled, variant = 'contained', size = 'large' } = props;
  return (
    <Button
      {...props}
      disabled={disabled || loading}
      size={size}
      variant={variant}
      sx={{
        ...props.sx,
        padding: '5px',
      }}
    >
      {loading ? <CircularProgress size={26} sx={{ color: '#fff' }} /> : <>{children}</>}
    </Button>
  );
};

export default LoadingButton;
